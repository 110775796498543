"use client";

import { useTranslation } from "@/app/i18n/client";
import { useState, useEffect, useRef } from "react";
import { Globe } from "lucide-react";

const languages = [
  { code: "en", name: "English" },
  { code: "vi", name: "Tiếng Việt" },
];

const LanguageSwitcher = ({ showLabel = false }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation("en", "common");
  const [language, setLanguage] = useState<any>(languages[0]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const language = languages.find((item) => item.code === i18n.language);
    setLanguage(language);

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    const language = languages.find((item) => item.code === langCode);
    setLanguage(language);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="app-input gap-2 rounded-full border p-1"
        aria-label="Change language"
      >
        {showLabel && language?.name && (
          <span className="pl-1">{language?.name}</span>
        )}
        <Globe className="h-5 w-5" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical">
            {languages.map((lang) => (
              <button
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code)}
                className={`${
                  i18n.language === lang.code
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700"
                } block w-full px-4 py-2 text-left text-sm hover:bg-gray-100`}
                role="menuitem"
              >
                {lang.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
