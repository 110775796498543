"use client";
import { useState, useEffect } from "react";
import { useTheme } from "next-themes";
import { Sun, Moon } from "lucide-react"; // Adjust the import based on your icon library

const ThemeSwitch = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <button
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
      className={`bg-surface-variant app-input relative flex h-7 w-14 items-center justify-between rounded-full border border-gray-300 transition-colors duration-300`}
    >
      <div
        className={`absolute flex h-[26px] w-[26px] items-center justify-center rounded-full transition-transform duration-300 ${
          theme === "dark"
            ? "translate-x-7 bg-gray-900"
            : "translate-x-0 bg-white"
        }`}
      >
        {theme === "dark" ? (
          <Moon size={20} strokeWidth="1" />
        ) : (
          <Sun size={20} strokeWidth="1" />
        )}
      </div>
    </button>
  );
};

export default ThemeSwitch;
